<template>
  <div class="project">
    <div class="project-title">
      <h2 class="text-center">Enna Alouette Birthday Project Credits</h2>
    </div>
    <div class="project-content">
      <h3 class="purple--text">The Afterword</h3>
      <p>
        Right after the 100K project,
        the Aloupeeps immediately started pitching projects for Enna's birthday.
        Such a passionate crowd they are, especially when it comes to their oshi.
        The previous project was already a success, but they wanted to step it up again.
        This time, there are song covers, podcasts, and multiple videos to relay
        their love and sincere birthday wishes to Enna.
      </p>
      <p>
        The first and foremost "thank you" is for Enna Alouette.
        We have said many times, you are the one who brought us together,
        you are the one making us smile, laugh, and inspired.
        In this special day, let us be the one giving back to you.
      </p>
      <p>
        This is the day when we appreciate that Enna Alouette graced our world.
        This day may have added 1 to your age, but isn't it a small price
        to pay for the next whole year of fun moments we'll enjoy together?
      </p>

      <h3 class="purple--text">Birthday Website</h3>
      <ul class="pb-4">
        <li><strong>jetrico</strong> - Project Lead, Lead Programmer, The Afterword</li>
        <li><strong>Professor Nobu</strong> - Room Planning, Art Guides, Start Screen</li>
        <li><strong>kurokur0kurO</strong> - Programming assist</li>
        <li><strong>Wikidude</strong> - Management assist</li>
        <li><strong>Elodie</strong> - Favicon, Sound Engineer</li>
        <li><strong>Arkazu</strong> - BGM file assist</li>
        <li>
          Artworks
          <ul>
            <li>Enna - <strong>Garfield Factory</strong></li>
            <li>Millie - <strong>jetrico</strong></li>
            <li>Nina - <strong>Professor Nobu</strong></li>
            <li>Reimu - <strong>Frank</strong></li>
            <li>Cake - <strong>Garfield Factory</strong></li>
            <li>Balloons w/ Aloupeeps - <strong>Jo-さん</strong></li>
            <li>Chef Aloupeep - <strong>6InchSenpaiΔ</strong></li>
            <li>Rack &amp; Radio - <strong>Frank</strong></li>
            <li>Sofa - <strong>Wikidude</strong></li>
            <li>Carpet - <strong>Jo-さん</strong></li>
            <li>Roomie - <strong>6InchSenpaiΔ</strong></li>
            <li>Gifts Pile - <strong>MinniJay</strong></li>
            <li>Animated Aloupeeps (8 variants) - <strong>Leef</strong></li>
            <li>Walls, Flooring - <strong>Professor Nobu</strong></li>
            <li>100K Silver Play Button - <strong>Professor Nobu</strong></li>
            <li>TV - <strong>Professor Nobu</strong></li>
            <li>Happy Birthday Banner - <strong>Professor Nobu</strong></li>
            <li>Table - <strong>Professor Nobu</strong></li>
            <li>Easel &amp; Painting - <strong>MB</strong></li>
            <li>Aloupeeps on the table - <strong>6InchSenpaiΔ</strong></li>
            <li>Cursor - <strong>Wikidude</strong></li>
          </ul>
        </li>
        <li>
          フリーBGM DOVA-SYNDROME OFFICIAL YouTube CHANNEL
          <ul>
            <li>"Someday (Prod. Khaim)" by Khaim</li>
          </ul>
        </li>
        <li>
          and everyone else on Ennacord giving suggestions, feedback, testing, and reporting bugs
        </li>
      </ul>

      <h3 class="purple--text">Aloupeeps Happy Birthday Enna Podcast Special</h3>
      <p class="ma-0">Happy Birthday Podcast for our favorite songbird!</p>
      <ul class="pb-4">
        <li><strong>Domo Espresso</strong> - Host</li>
        <li><strong>DarkDisasterKid</strong> - Host</li>
        <li><strong>BONER</strong> - Host</li>
        <li><strong>Elodie</strong> - Sound Engineer</li>
      </ul>

      <h3 class="purple--text">An Aloupeep Birthday Anthem</h3>
      <p class="ma-0">An acapella tribute to wish our wonderful songbird a Happy Birthday!</p>
      <ul class="pb-4">
        <li>Video - <strong>Soul Jam</strong></li>
        <li>Arrangement - <strong>Soul Jam</strong></li>
        <li>Alternate lyrics - <strong>Soul Jam</strong></li>
        <li>Mix - <strong>DJ CVRRY / Soul Jam</strong></li>
        <li>
          Vocals
          <ul>
            <li>
              <strong>
                aletheia, AllSoonNoDairy, Arkazu, coronetto, DarkDisasterKid,
                Dippy (Professional Simp), Domo Espresso, EksPtt, hiya! kk, MugList,
                Ralphy, Sasu Crow, Soul Jam, TG, troosh, Verm
              </strong>
            </li>
          </ul>
        </li>
        <li>Artwork - <strong>MinniJay</strong></li>
        <li>
          Aloupeep Art
          <ul>
            <li>
              <strong>
                Leef, Keektang, 6InchSenpai, Jo-さん
              </strong>
            </li>
          </ul>
        </li>
      </ul>

      <h3 class="purple--text">Birthday Cake Baking Relay</h3>
      <p class="ma-0">A relay type video with the Aloupeeps baking a cake for Enna's Birthday</p>
      <ul class="pb-4">
        <li>Project Lead - <strong>BONER</strong></li>
        <li>Relay Captions - <strong>Professor Nobu, Arkazu</strong></li>
        <li>Video Editing - <strong>Domo Espresso</strong></li>
        <li>Intro and Outro Videos - <strong>SoloSnivy</strong></li>
        <li>Design - <strong>Elodie</strong></li>
        <li>
          Baking Aloupeeps
          <ul>
            <li>
              <strong>
                AllSoonNoDairy, Altina, Arkteezy, Bingu, BONER, Broney Stinson, Crono,
                DarkDisasterKid, Domo Espresso, Jo-さん, Nuit [夜], Petras Emergency Food,
                Soul Jam, Thapperson Apple, Zer0Pendragon6
              </strong>
            </li>
          </ul>
        </li>
        <li>
          Singing Happy Birthday
           <ul>
            <li>
              <strong>
                Nuit [夜], Soul Jam, MiikeMQ, Professor Nobu, SasuCrow, jetrico
              </strong>
            </li>
          </ul>
        </li>
        <li>
          フリーBGM DOVA-SYNDROME OFFICIAL YouTube CHANNEL
          <ul>
            <li>全てを創造する者「Dominus Deus」 written by KK</li>
          </ul>
        </li>
        <li>
          Songs from YouTube Audio Library License
          <ul>
            <li>"Happy Birthday Bouncy - E's Jammy Jams” by E’s Jammy Jams</li>
            <li>"Happy Birthday Jazz Mambo - E's Jammy Jams” by E’s Jammy Jams</li>
            <li>"Happy Birthday Jazz Old Timey - E's Jammy Jams” by E’s Jammy Jams</li>
            <li>"Happy Birthday Jazz Waltz - E's Jammy Jams” by E’s Jammy Jams</li>
          </ul>
        </li>
      </ul>

      <h3 class="purple--text">Happy birthday from the Aloupeeps</h3>
      <p class="ma-0">The Aloupeeps wishing an Happy Birthday to our dear Songbird.</p>
      <ul class="pb-4">
        <li>Project Lead - <strong>Altina</strong></li>
        <li>
          Sound Engineering And Wish of This Songbird Piano Jingles
          - <strong>SasuCrow</strong>
        </li>
        <li>Video Editing - <strong>Domo Espresso</strong></li>
        <li>Birthday Slides Design - <strong>Elodie, Professor Nobu</strong></li>
        <li>Intro And Outro Video - <strong>SoloSnivy</strong></li>
        <li>
          Artists - In Order of Appearance
          <ul>
            <li>
              <strong>
                @KonKoffee, @6InchSenpai, @baxei2
              </strong>
            </li>
          </ul>
        </li>
        <li>
          Birthday Messages
          <ul>
            <li>
              <strong>
                6InchSenpai, Acerola, Airi, Altina, Amat, Boner, Coro-chan, Crono,
                DarkDisasterKid, Domo Espresso, Dippy (Professional Simp), Decimater001,
                Elodie, EmotionalSupportStrappon, Frank, Garfield Factory, Haoseng, JeEztli,
                jetrico, Jo-さん, KakaTan, Keres Crawford, koshka-sova, Kuroshi, Levi,
                MiikeMQ, MugList, Professor Nobu, Ryuji Scarlet, Sasu Crow, Snu Snu, TG,
                Thapperson Apple, Verm, Yui-chan, Zer0Pendragon6
              </strong>
            </li>
          </ul>
        </li>
        <li>
          All music used in this video is used under the YouTube Audio Library License
          <ul>
            <li>“Happy Birthday 80s - Es Jammy Jams” by E’s Jammy Jams</li>
            <li>"Happy Birthday Jazz Waltz - E's Jammy Jams” by E’s Jammy Jams</li>
          </ul>
        </li>
      </ul>

      <h3 class="purple--text">God Sees All - Aloupeeps-  Cover</h3>
      <p class="ma-0">
        A cover done by the Enna Alouette Fan server for Enna Alouette on her Birthday
      </p>
      <ul class="pb-4">
        <li><strong>Dippy</strong> - Project Head</li>
        <li><strong>Akello</strong> - Cover Instrumental</li>
        <li><strong>DJCVRRY</strong> - Mixing</li>
        <li><strong>SasuCrow</strong> - Mixing, Sample Cutting</li>
        <li><strong>Wick (Andy)</strong> - MV</li>
        <li><strong>Domo Espresso</strong> - wrangling Dippy</li>
        <li>
          Art
          <ul>
            <li>
              <strong>
                Keektang, Leef, 6InchSenpai, MinniJay
              </strong>
            </li>
          </ul>
        </li>
        <li>
          Vocals
          <ul>
            <li>
              <strong>
                Airi, Arkazu, Verm, Hiya! Kk, koshka-sova, AllSoonNoDairy, MugList, Ralphy, EksPtt,
                Sen, Dippy, Kuroshi, Altina, troosh, MiikeMQ, DarkDisasterKid, Coronetto, TG,
                obviebear, CPU Violet, Soul Jam, Razy, Domo Espresso, SasuCrow, hyuni
              </strong>
            </li>
          </ul>
        </li>
      </ul>

      <h3 class="purple--text">Birthday Dance</h3>
      <p class="ma-0">Enna's Birthday present from Soramari and kaochiicos</p>
      <ul class="pb-4">
        <li><strong>Soramari</strong></li>
        <li><strong>kaochiicos</strong></li>
      </ul>

      <h3 class="purple--text">Drawing Board</h3>
      <ul class="pb-4">
        <li><strong>jetrico</strong> - Board Admin</li>
        <li>
          jetrico, Airi Flanny, Leef, Frank, OrangeHenge, MBisnotmybad,
          Professor Nobu, luminarun, coronetto, DayJam, SoloSnivy, Renju,
          Sorekasho, mikofries, Garfield Factory, verm, lettesan, hiya! kk,
          Jo-さん, Koguma, Domo Espresso, K___K, reirupan, MinniJay, Yui-chan,
          Leoje, mono_luca, Thapperson Apple
        </li>
      </ul>

      <h3 class="purple--text">Message Cards</h3>
      <ul class="pb-4">
        <li><strong>jetrico</strong> - Message Admin</li>
        <li><strong>Domo Espresso</strong> - Message Admin</li>
        <li>
          Zer0Pendragon6, Crono, Airi, MB, Frank, Myrrh, Katchu Katchu, ZDitto,
          Yui-chan, guGGy, Jo-さん, Leoje, Cinnamon, メロン, NoName005, sharialene,
          CT, D12, SunriseValley, Thraxelon, Esteesy, Death by Snu Snu, Elodie Fiorella,
          Arkazu, Thapperson Apple, Keres Crawford, Breikah, Garfield Factory, Shopowner,
          AdelLevi, WhiteGuyGraal, Domo Espresso, House, Sola, Bionic608, rimi-rin, Lee,
          Elgrim, Binns, Aya, Zhumingz, jetrico, Sorekasho, kalmen, miilk, revenant77,
          eSPer, TinyTurtle, Holo.txt, Renju, KonKoffee, SunshineWongster, verm, Cheliax,
          Kovaleski, Eve Reese , Pinkerton, Dippy, Riacu, Limpy Boennar, Latte, RyujiScarlet,
          koshka-sova, IceHism, Acerola Ch., hiya! kk, Kurou, maperu マペル, MiikeMQ, Mr. Y,
          Rinora, DeaLoux, OrangeHenge, mono_luca, SoloSnivy, def, DarkDisasterKid, erie,
          robi, MrSDS, Saeren, Nuit [夜], Obviebear, Enna's Ribbon, Arrhur Taveira, Lily Mu,
          Professor Nobu, Erin, BlackfireHedgehog
        </li>
      </ul>

      <h3 class="purple--text">Special Thanks to:</h3>
      <ul class="pb-4">
        <li>@everyone in Ennacord</li>
        <li><strong>MiikeMQ</strong> - Ennacord Founder</li>
        <li><strong>Elodie</strong> - Announcements</li>
        <li><strong>Domo Espresso</strong> - Announcements</li>
      </ul>
    </div>
    <div class="project-close">
      <v-btn
        @click="$root.$emit('closeProject')"
        block large elevation="1" color="purple lighten-4">
        Close
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
.project {
  height:90vh;
  position:relative;
  .project-title {
    left:0;
    right:0;
  }
  .project-content {
    position:absolute;
    top: 50px;
    left:0;
    right:0;
    bottom:110px;
    overflow-y:scroll;
    background:#f0f0f0;
    padding:10px 20px;
  }
  .project-close {
    position:absolute;
    bottom:40px;
    left:0;
    right:0;
  }
}
</style>
