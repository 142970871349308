module.exports = {
  bg: {
    texture: 'room',
    x: 0.5,
    y: 0.5,
    z: 1,
    scale: 1.03,
    str: 1.6,
    ox: 0.5,
    oy: 0.492,
  },
  room: {
    x: 0.5,
    y: 0.5,
    z: 2,
    str: 1,
    ox: 0.5,
    oy: 0.5,
  },
  carpet: {
    x: 0.5,
    y: 0.89,
    z: 5,
    str: 0.9,
    ox: 0.5,
    oy: 0.5,
  },
  gifts: {
    texture: 'messages',
    x: 0.7,
    y: 0.55,
    z: 6,
    str: 1.2,
    ox: 0.5,
    oy: 0.5,
    text: 'Message Cards',
    project: 'messages',
    font: 40,
    dir: 'top',
    audio: 'messages',
  },
  banner: {
    x: 0.5,
    y: 0.08,
    z: 7,
    scale: 1.1,
    str: 1.3,
    ox: 0.5,
    oy: 0.5,
    dir: 'top',
  },
  balloonL: {
    texture: 'balloon-l',
    x: 0.1,
    y: 0.23,
    z: 4,
    str: 1.6,
    scale: 0.9,
    ox: 0.5,
    oy: 0.5,
    dir: 'top',
  },
  balloonR: {
    texture: 'balloon-r',
    x: 0.86,
    y: 0.16,
    z: 4,
    str: 1.3,
    scale: 0.9,
    ox: 0.5,
    oy: 0.5,
    dir: 'top',
  },
  couch: {
    x: 0.14,
    y: 0.68,
    z: 101,
    str: 1.1,
    ox: 0.5,
    oy: 0.5,
    dir: 'top',
  },
  rack: {
    x: 0.915,
    y: 0.6,
    z: 100,
    str: 0.7,
    ox: 0.5,
    oy: 0.5,
    dir: 'right',
  },
  radio: {
    x: 0.949,
    y: 0.35,
    z: 101,
    str: 0.6,
    ox: 0.3,
    oy: 0.5,
    text: 'Toggle Sounds',
    project: null,
    font: 35,
    dir: 'right',
  },
  tv: {
    x: 0.96,
    y: 0.61,
    z: 101.8,
    str: 0.6,
    ox: 0.5,
    oy: 0.5,
  },
  ap01: {
    x: 0.3,
    y: 0.87,
    z: 101.8,
    str: 0.9,
    scale: 1,
    ox: 0.5,
    oy: 0.5,
    flip: true,
    text: 'Happy Birthday!',
    project: 'video01',
    font: 40,
    dir: 'top',
    // audio: 'rickroll',
  },
  ap02: {
    x: 0.8,
    y: 0.4,
    z: 101.8,
    str: 0.7,
    scale: 1.1,
    ox: 0.5,
    oy: 0.5,
  },
  ap03: {
    x: 0.9,
    y: 0.8,
    z: 101.8,
    str: 0.78,
    scale: 1,
    ox: 0.5,
    oy: 0.5,
  },
  ap04: {
    x: 0.47,
    y: 0.9,
    z: 112,
    str: -0.3,
    scale: 1,
    ox: 0.5,
    oy: 0.5,
    flip: true,
  },
  ap05: {
    x: 0.3,
    y: 0.35,
    z: 90,
    str: 0.65,
    scale: 1.1,
    ox: 0.5,
    oy: 0.5,
    flip: true,
  },
  ap06: {
    x: 0.74,
    y: 0.8,
    z: 101.8,
    str: 0.85,
    scale: 0.75,
    ox: 0.5,
    oy: 0.5,
    text: 'Artworks',
    project: 'artworks',
    font: 40,
    dir: 'top',
    audio: 'mural',
  },
  ap07: {
    x: 0.2,
    y: 0.6,
    z: 101.8,
    str: 1.1,
    scale: 1,
    ox: 0.5,
    oy: 0.5,
  },
  ap08: {
    x: 0.6,
    y: 0.45,
    z: 101.8,
    str: 0.7,
    scale: 1,
    ox: 0.5,
    oy: 0.5,
  },
  ap09: {
    x: 0.35,
    y: 0.63,
    z: 101.8,
    str: 1.1,
    scale: 1,
    ox: 0.5,
    oy: 0.5,
    flip: true,
  },
  ap10: {
    x: 0.44,
    y: 0.714,
    z: 109,
    scale: 1,
    str: -0.25,
    ox: 0.5,
    oy: 0.5,
  },
  elodie: {
    x: 0.5,
    y: 0.6,
    z: 108,
    str: 0,
    scale: 0.9,
    dir: 'bottom',
  },
  elodieenna: {
    x: 0.5,
    y: 0.6,
    z: 108,
    str: 0,
    scale: 0.9,
    dir: 'bottom',
  },
  table: {
    x: 0.5,
    y: 0.88,
    z: 110,
    scale: 1,
    str: -0.3,
    ox: 0.5,
    oy: 0.5,
    dir: 'bottom',
  },
  cake: {
    x: 0.51,
    y: 0.83,
    z: 111,
    str: -0.45,
    ox: 0.5,
    oy: 0.5,
    text: 'Blow the Candle!',
    project: 'cake',
    font: 30,
    dir: 'top',
  },
};
