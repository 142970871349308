import GoogleFontsPlugin from './google-fonts';

export default {
  global: [
    {
      key: 'GoogleFontsPlugin',
      plugin: GoogleFontsPlugin,
      mapping: 'googleFonts',
    },
  ],
  scene: [
  ],
};
