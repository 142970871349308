module.exports = {
  video01: {
    title: 'Happy Birthday Elodie, from Ennacord!',
    description: '',
    warnings: '',
    lead: '',
    credits: '',
    video: 'https://www.youtube.com/embed/QKRam_ERcGw',
  },
};
